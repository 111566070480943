.score-board {
    width: 320px;
    display: grid;
    grid-auto-flow: column;
    margin-top: 5px;
}

.score-board div {
    color: white;
    text-align: center;
    font-size: 2em;
    padding: 5px;
    font-weight: bold;
    background-color: #4392f1;
}

.score-board div:first-child {
    background-color: #CD493A;
}