.header {
    height: 50px;
    width: 420px;
    display: flex;
    color: white;
    border-bottom: 1px solid rgb(136, 136, 136);
    align-items: center;
    justify-content: center;
  }
  
  .text {
    box-sizing: border-box;
    margin: 0%;
  }